<template>

    <div class="h-screen p-[1vw]" v-if="acfData.orientation === 'landscape'">
        <div class="flex flex-row gap-[2%] h-full">
            <div class="w-[50vw] flex">
                <div class="relative flex h-full w-full" v-if="acfData.video_or_image === 'video'">
                    <div class="absolute top-0 left-0 right-0 bottom-0">
                        <video ref="videoRef" class="object-cover h-full w-full" muted autoplay loop>
                            <source :src="acfData.video.url" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

                <div class="relative flex h-full w-full" v-if="acfData.video_or_image === 'image'">
                    <div class="absolute top-0 left-0 right-0 bottom-0">
                        <img class="object-cover w-full h-full" :src="acfData.image.url" alt="">
                    </div>
                </div>
            </div>

            <div class="w-[50vw]">
                <div v-if="leaderboard.length > 0" class="text-center mb-[2vh]">
                    <p class="current-leader-text">Just nu:</p>
                    <p class="leader-text text-white relative">
                        <span class="name-highlight">
                            <svg width="84" height="97" viewBox="0 0 84 97" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M52.9199 62.7405L7.84772 39.8778M80.2222 49.3802L58.6588 3.67212M54.5 93.5001H3.5"
                                    stroke="#FFF" stroke-width="7" stroke-linecap="round" />
                            </svg>
                            {{ leaderboard[0].name }}
                            <svg width="85" height="97" viewBox="0 0 85 97" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M32.08 62.7404L77.1523 39.8776M4.32788 49.38L25.8913 3.672M30.5 93.5H81.5"
                                    stroke="#FFF" stroke-width="7" stroke-linecap="round" />
                            </svg>
                        </span>
                        vet mest!
                    </p>
                </div>

                <div class="bg-lightblue p-[2vw] rounded-[70px] mb-[2vh]">
                    <h2 style="font-size:calc(42px + 1vh);" class="text-white text-center font-medium">Topp 10 idag:
                    </h2>
                    <svg class="w-full mb-[5%]" viewBox="0 0 823 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1H822L87.8365 22.25L666.747 26" stroke="#FFF" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <div class="flex flex-row" v-if="leaderboard">
                        <ol class="w-1/2 list-inside">
                            <li v-for="(participant, index) in leaderboard.slice(0, 5)"
                                style="font-size:calc(32px + 0.2vh);" class="text-white font-medium mb-[2%]">{{ index +
                                    1
                                }}. {{
                                    participant.name }}
                            </li>
                        </ol>
                        <ol class="w-1/2 list-inside">
                            <li v-for="(participant, index) in leaderboard.slice(5, 10)"
                                style="font-size:calc(32px + 0.2vh);" class="text-white font-medium mb-[2%]">
                                {{ index + 6 }}. {{ participant.name }}
                            </li>
                        </ol>
                    </div>
                </div>

                <div class="flex flex-row gap-[2%] items-center justify-center">
                    <div class="w-2/3 flex flex-col justify-center items-center">
                        <h3 style="font-size:calc(24px + 0.2vh);" class="font-medium leading-[1.1] text-white mb-[2%]">
                            Vill du
                            utmana?
                            Skanna koden, svara på frågorna och ta chansen.
                            De 10 bästa är med i utlottningen av ett resepresentkort värt <span
                                class="whitespace-nowrap">10
                                000 kr!</span></h3>
                    </div>
                    <div class="w-1/4 flex flex-row items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 1155 1155">
                            <path fill="#FFF" d="M0 0h1155v1155H0z" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 70)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 70)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 70)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 70)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 70)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 70)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 70)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 70)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 105)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 105)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 105)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 105)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 105)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 105)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 105)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 140)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 140)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 140)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 140)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 140)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 175)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 175)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 175)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 175)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 175)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 175)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 210)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 210)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 210)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 210)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 210)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 245)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 245)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 245)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 245)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 245)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 245)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 280)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 280)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 280)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 280)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 280)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 280)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 280)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 315)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 315)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 315)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 315)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 315)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 315)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 315)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 70 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 175 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 245 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 280 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 315 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 350)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 70 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 175 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 245 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 805 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 385)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 70 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 105 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 280 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 805 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 420)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 455)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 175 455)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 455)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 245 455)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 315 455)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 455)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 455)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 455)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 455)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 455)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 455)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 105 490)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 490)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 245 490)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 280 490)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 490)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 490)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 490)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 490)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 490)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 490)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 490)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 490)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 490)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 490)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 70 525)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 525)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 525)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 525)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 525)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 525)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 525)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 525)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 525)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 525)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 525)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 525)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 525)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 70 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 175 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 280 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 315 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 560)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 595)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 175 595)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 245 595)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 315 595)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 595)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 595)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 595)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 595)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 595)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 595)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 595)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 595)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 70 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 280 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 315 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 805 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 630)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 105 665)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 665)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 175 665)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 665)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 665)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 665)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 665)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 665)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 665)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 665)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 665)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 665)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 70 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 245 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 280 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 700)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 175 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 245 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 315 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 735)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 105 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 280 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 315 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 805 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 770)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 805)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 805)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 805)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 805)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 805)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 805)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 805)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 805)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 805)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 805)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 805)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 805)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 805)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 805)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 840)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 840)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 840)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 840)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 840)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 840)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 840)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 840)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 840)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 840)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 840)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 840)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 875)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 875)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 875)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 875)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 875)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 875)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 875)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 875)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 875)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 910)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 910)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 910)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 805 910)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 910)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 910)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 910)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 910)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 910)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 945)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 945)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 945)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 945)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 945)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 805 945)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 945)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 945)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 945)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 945)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 980)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 980)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 980)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 980)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 980)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 980)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 980)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 980)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 980)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 1015)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 1015)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 1015)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 1015)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 1015)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 1015)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 1015)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 1015)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 1050)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 1050)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 1050)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 1050)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 1050)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 1050)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 1050)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 1050)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 1050)" />
                            <g style="fill:#000">
                                <path d="M15 15h70v70H15z" style="fill:none" transform="matrix(2.45 0 0 2.45 70 70)" />
                                <path d="M278.25 70H70v245h245V70h-36.75zm0 208.25h-171.5v-171.5h171.5v171.5z" />
                            </g>
                            <g style="fill:#000">
                                <path d="M15 15h70v70H15z" style="fill:none" transform="matrix(2.45 0 0 2.45 840 70)" />
                                <path d="M1048.25 70H840v245h245V70h-36.75zm0 208.25h-171.5v-171.5h171.5v171.5z" />
                            </g>
                            <g style="fill:#000">
                                <path d="M15 15h70v70H15z" style="fill:none" transform="matrix(2.45 0 0 2.45 70 840)" />
                                <path d="M278.25 840H70v245h245V840h-36.75zm0 208.25h-171.5v-171.5h171.5v171.5z" />
                            </g>
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(1.05 0 0 1.05 140 140)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(1.05 0 0 1.05 910 140)" />
                            <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(1.05 0 0 1.05 140 910)" />
                        </svg>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <div v-if="acfData.orientation === 'portrait'" class="tv-wrapper">
        <div v-if="acfData.video_or_image === 'video'">
            <video v-if="acfData.video" ref="videoRef" class="w-full h-auto mb-[5%]" muted autoplay>
                <source :src="acfData.video.url" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>

        <div v-if="acfData.video_or_image === 'image'">
            <img class="w-full h-auto mb-[5%]" :src="acfData.image.url" alt="">
        </div>

        <div v-if="leaderboard.length > 0" class="text-center mb-[5%]">
            <p class="current-leader-text">Just nu:</p>
            <p class="leader-text text-white relative">
                <span class="name-highlight">
                    <svg width="84" height="97" viewBox="0 0 84 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M52.9199 62.7405L7.84772 39.8778M80.2222 49.3802L58.6588 3.67212M54.5 93.5001H3.5"
                            stroke="#FFF" stroke-width="7" stroke-linecap="round" />
                    </svg>
                    {{ leaderboard[0].name }}
                    <svg width="85" height="97" viewBox="0 0 85 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32.08 62.7404L77.1523 39.8776M4.32788 49.38L25.8913 3.672M30.5 93.5H81.5"
                            stroke="#FFF" stroke-width="7" stroke-linecap="round" />
                    </svg>
                </span>
                vet mest!
            </p>
        </div>


        <div class="bg-lightblue p-[5%] rounded-[70px] mb-[5%]">
            <h2 style="font-size:calc(42px + 0.5vw);" class="text-white text-center font-medium">Topp 10 idag:</h2>
            <svg class="w-full mb-[5%]" viewBox="0 0 823 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H822L87.8365 22.25L666.747 26" stroke="#FFF" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
            <div class="flex flex-row" v-if="leaderboard">
                <ol class="w-1/2 list-inside">
                    <li v-for="(participant, index) in leaderboard.slice(0, 5)" style="font-size:calc(32px + 0.5vw);"
                        class="text-white font-medium mb-[2%]">{{ index + 1
                        }}. {{
                            participant.name }}
                    </li>
                </ol>
                <ol class="w-1/2 list-inside">
                    <li v-for="(participant, index) in leaderboard.slice(5, 10)" style="font-size:calc(32px + 0.5vw);"
                        class="text-white font-medium mb-[2%]">
                        {{ index + 6 }}. {{ participant.name }}
                    </li>
                </ol>
            </div>
        </div>

        <div class="flex flex-row gap-[2%] pb-[2%]">
            <div class="w-2/3 flex flex-col justify-center items-center">
                <h3 style="font-size:calc(37px + 0.5vw);" class="font-medium leading-[1.1] text-white mb-[2%]">Vill du
                    utmana?
                    Skanna koden, svara på frågorna och ta chansen.
                    De 10 bästa är med i utlottningen av ett resepresentkort värt <span class="whitespace-nowrap">20
                        000 kr!</span></h3>
            </div>
            <div class="w-1/3 flex flex-row items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 1155 1155">
                    <path fill="#FFF" d="M0 0h1155v1155H0z" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 70)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 70)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 70)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 70)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 70)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 70)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 70)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 70)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 105)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 105)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 105)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 105)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 105)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 105)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 105)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 140)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 140)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 140)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 140)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 140)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 175)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 175)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 175)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 175)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 175)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 175)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 210)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 210)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 210)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 210)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 210)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 245)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 245)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 245)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 245)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 245)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 245)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 280)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 280)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 280)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 280)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 280)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 280)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 280)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 315)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 315)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 315)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 315)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 315)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 315)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 315)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 70 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 175 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 245 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 280 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 315 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 350)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 70 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 175 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 245 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 805 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 385)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 70 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 105 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 280 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 805 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 420)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 455)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 175 455)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 455)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 245 455)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 315 455)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 455)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 455)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 455)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 455)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 455)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 455)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 105 490)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 490)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 245 490)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 280 490)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 490)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 490)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 490)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 490)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 490)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 490)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 490)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 490)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 490)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 490)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 70 525)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 525)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 525)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 525)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 525)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 525)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 525)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 525)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 525)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 525)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 525)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 525)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 525)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 70 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 175 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 280 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 315 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 560)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 595)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 175 595)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 245 595)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 315 595)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 595)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 595)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 595)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 595)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 595)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 595)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 595)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 595)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 70 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 280 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 315 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 805 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 630)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 105 665)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 140 665)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 175 665)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 665)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 665)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 665)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 665)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 665)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 665)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 665)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 665)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 665)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 70 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 245 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 280 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 700)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 175 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 210 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 245 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 315 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 735)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 105 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 280 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 315 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 805 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 770)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 805)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 805)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 805)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 805)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 805)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 805)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 805)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 805)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 805)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 805)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 805)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 805)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 805)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 805)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 840)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 840)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 840)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 840)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 840)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 840)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 840)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 840)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 840)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 840)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 840)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 840)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 875)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 875)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 875)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 875)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 875)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 875)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 875)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 875)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 875)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 910)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 910)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 910)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 805 910)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 840 910)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 910)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 910)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 910)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 910)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 945)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 945)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 945)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 945)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 945)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 805 945)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 945)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 945)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 945)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 945)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 980)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 385 980)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 490 980)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 980)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 665 980)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 980)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 875 980)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 980 980)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1050 980)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 1015)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 525 1015)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 595 1015)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 630 1015)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 1015)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 1015)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 945 1015)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 1015)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 350 1050)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 420 1050)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 455 1050)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 560 1050)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 700 1050)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 735 1050)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 770 1050)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 910 1050)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(.35 0 0 .35 1015 1050)" />
                    <g style="fill:#000">
                        <path d="M15 15h70v70H15z" style="fill:none" transform="matrix(2.45 0 0 2.45 70 70)" />
                        <path d="M278.25 70H70v245h245V70h-36.75zm0 208.25h-171.5v-171.5h171.5v171.5z" />
                    </g>
                    <g style="fill:#000">
                        <path d="M15 15h70v70H15z" style="fill:none" transform="matrix(2.45 0 0 2.45 840 70)" />
                        <path d="M1048.25 70H840v245h245V70h-36.75zm0 208.25h-171.5v-171.5h171.5v171.5z" />
                    </g>
                    <g style="fill:#000">
                        <path d="M15 15h70v70H15z" style="fill:none" transform="matrix(2.45 0 0 2.45 70 840)" />
                        <path d="M278.25 840H70v245h245V840h-36.75zm0 208.25h-171.5v-171.5h171.5v171.5z" />
                    </g>
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(1.05 0 0 1.05 140 140)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(1.05 0 0 1.05 910 140)" />
                    <path d="M0 0h100v100H0z" style="fill:#000" transform="matrix(1.05 0 0 1.05 140 910)" />
                </svg>
            </div>
        </div>
    </div>



</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import headerImage from '@assets/img/start.jpg'

export default {
    name: 'ResultsScreen',
    computed: {
        headerImage() {
            return headerImage
        }
    },
    setup() {

        const store = useStore()
        const leaderboard = computed(() => store.state.leaderboard)
        const acfData = ref([])
        const videoRef = ref(null)


        function getACFData() {
            acfData.value = []

            fetch('/wp-json/custom/v1/acf-options/')
                .then((response) => response.json())
                .then((data) => {
                    //console.log(data)
                    if (data) {
                        acfData.value = data
                    }
                })
                .catch((error) => {
                    console.error('error:', error)
                })
        }

        function getLeaderBoard() {
            var data = {
                action: 'GetLeaderBoard',
            }

            fetch(ajaxUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: new URLSearchParams(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.success) {
                        store.commit('leaderboard', data.leaderboard)
                    }
                })
                .catch((error) => {
                    console.error('error:', error)
                })
        }

        // const onVideoEnded = () => {
        //     getACFData()
        // };

        // watch(videoRef, (newData) => {
        //     if (newData) {
        //         console.log(videoRef.value)
        //         videoRef.value.addEventListener('ended', onVideoEnded, false)
        //     }
        // })

        onMounted(() => {

            getACFData()
            watch(acfData, (newData) => {
                if (newData.video && videoRef.value) {
                    videoRef.value.play().catch(error => {
                        console.error('Autoplay failed:', error)
                    })
                }
            })
            getLeaderBoard()

            setInterval(() => {
                getLeaderBoard()
            }, 5000)

        })
        return {
            leaderboard,
            acfData,
            videoRef
        }
    }
}
</script>

<style scoped>
.tv-wrapper {
    @apply w-[90%] mx-auto;
}

.tv-heading {
    font-size: calc(32px + 0.2vh);
    line-height: 1.1;
    font-weight: 500;
}

.current-leader-text {
    font-size: calc(46px + 0.2vh);
    line-height: 1.1;
    font-weight: 500;
    @apply text-white mb-[2%];
}

.leader-text {
    font-size: calc(66px + 0.2vh);
    line-height: 1.1;
    font-weight: 500;
}

.name-highlight {
    @apply flex flex-row items-center justify-center gap-[2%] font-bold;
}

.name-highlight svg {
    position: relative;
    margin-top: -5%;
}


.tv-text {
    font-size: calc(16px + 0.2vh);
    line-height: 1.6;
}
</style>